<template>
    <div v-if="noticias.length > 0" class="col-12">
        <div class="card">
            <h5>Notícias</h5>


            <div class="grid dashboard">
                <div v-for="(noticia) in noticias" :key="noticia.id" class="col-12 lg:col-4">
                    <div class="card card-size height-100 flex flex-column">
                        <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                            <!-- <img v-bind:src="'data:image/jpg;base64,'+noticia.imagemPrincipal"/> -->
                            <img v-bind:src="noticia.imagemPrincipal" class="noticia-image" />
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h6 class="mb-2">{{ noticia.titulo }}</h6>
                            <p class="muted-text" v-html="noticia.resumo" ></p>
                        </div>
                        <div class="pt-3 mt-auto" style="text-align: right;">
                            <!-- <small hidden class="muted-text"><i class="pi pi-heart"> 0</i></small> -->
                            <a class="p-button p-component mr-2 mb-2" :href="noticia.urlSiteIntegrada" target="_blank">Continuar Lendo</a>
                            
                        </div>
                    </div>
                </div>
            </div>

            <Paginator
                v-model:first="firstRow"
                :rows="qtdRegistro"
                :total-records="totalRegistro"
                @page="onPage" />


        </div>

	</div>
</template>

<script lang="js">
   
    import NoticiaService from "../../service/NoticiaService.js"
    import Carousel from 'primevue/carousel';
    export default {
        name: 'Noticia', 
        data() {
            return {
                noticias: [],
                item5: [
                    { label: "Mais notícias", icon: "pi pi-fw pi-search" }
                ],
                pagina: 0,
                firstRow: 0,
                totalRegistro: 0,
                qtdRegistro: 3, 
                perPage: 3, 
            }
        },
        components: {Carousel},
        methods: {
            getNoticias(page) {
                NoticiaService.getNoticiasPaginada(page, this.perPage).then((response) => {
                    this.noticias = response.data.noticias;
                    this.totalRegistro = response.data.totalElementos;
                });
                
            },

            onPage(event) {          
                this.pagina = event.page + 1;
                this.firstRow = event.rows * event.page;
                this.qtdRegistro = event.rows;                     
                this.getNoticias(this.pagina);
            },
        },
        created() {
           
        },
        mounted() {
         this.getNoticias(1);
        }
        
    }
    
</script>

<style scoped>
    .content-alt-bgcolor {
        background-color: #f1f1f1 !important;
    }

    .card-size{
        min-height: 510px !important;
    }

    .noticia-image {
        width: 100%;
        max-width: 30rem;
    }
</style>