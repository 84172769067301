import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/noticias`;

class NoticiaService {

    getNoticias() {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/capa`);
    }
    
    getNoticiasPaginada(page, perPage) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/capa/${page}/${perPage}`);
    }
}

export default new NoticiaService();