<template>
	<div class="grid dashboard">
		<CapitalSocial :matricula="currentUser?.matricula"/>
		<ContasPagar :matricula="currentUser?.matricula"/>
        <ContasReceber :matricula="currentUser?.matricula"/>
        <Mensagens :matricula="currentUser?.matricula"/>
		<Noticia/>
        <PrecosIntegrada :matricula="currentUser?.matricula"/>
        <!-- <PrevisaoTempo :codigo-cidade="codigoCidade"/> -->
	</div>

    <ModalAceiteTermos :exibirModalTermos="exibirModalTermos" 
            :aceiteTermoUso="dataAceiteTermoUso" 
            :aceitePoliticaPrivacidade="dataAceitePoliticaPrivacidade" />
</template>

<script>
import ProductService from '../service/ProductService';
import Noticia from '../components/consulta/Noticias.vue';
import CapitalSocial from '../components/widgets/CapitalSocial.vue';
import PrecosIntegrada from '../components/consulta/PrecosIntegrada.vue';
//import PrevisaoTempo from '../components/consulta/PrevisaoTempo.vue';
import ContasPagar from './widgets/ContasPagar.vue';
import ContasReceber from './widgets/ContasReceber.vue';
import Mensagens from './widgets/Mensagens.vue';
import ModalAceiteTermos from './ModalAceiteTermos.vue';
import moment from 'moment';

export default {
    data() {
        return {
			codigoCidade: 'Londrina-PR',
            products: null,
            show:true,
            exibirModalTermos: false,
        };
    },
    productService: null,
    dataAceiteTermoUso: null,
    dataAceitePoliticaPrivacidade: null,
    created() {
        this.productService = new ProductService();
        const ultimaAtualizacaoAceites = new Date("2024-08-12T13:28:55.123");
        const ultimoRegistroTermoUso = new Date(this.$store.state.auth.user.cooperado.dataAceiteTermoUso)
        const ultimoRegistroPoliticaPrivacidade = new Date(this.$store.state.auth.user.cooperado.dataAceitePoliticaPrivacidade)

        const termoDeUsoAntes = moment(ultimoRegistroTermoUso).isBefore(ultimaAtualizacaoAceites);
        const politicaDePrivacidadeAntes = moment(ultimoRegistroPoliticaPrivacidade).isBefore(ultimaAtualizacaoAceites);

        if (termoDeUsoAntes || politicaDePrivacidadeAntes) {
            this.dataAceitePoliticaPrivacidade = null;
            this.dataAceiteTermoUso = null;
            this.exibirModalTermos = true;
            this.validarTermos();
            return;
        }

        this.dataAceiteTermoUso = this.$store.state.auth.user.cooperado.dataAceiteTermoUso;
        this.dataAceitePoliticaPrivacidade = this.$store.state.auth.user.cooperado.dataAceitePoliticaPrivacidade;
        this.validarTermos();
    },
    mounted() {
        this.productService.getProducts().then(data => this.products = data);
    },
    watch: {
        "$appState.isNewThemeLoaded"(isLoaded) {
            if (isLoaded) {
                this.$appState.isNewThemeLoaded = false;
            }
        }
    },
    methods: {
        getCurrentUser() {
            return this.$store.state.auth.user;
        },
        
        formatCurrency(value) {
            return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
        },

        validarTermos() {
            let admin = this.getCurrentUser().administrador;
            
            if (!admin && (this.dataAceiteTermoUso == null 
                    || this.dataAceitePoliticaPrivacidade == null)) {
                this.exibirModalTermos = true;
            }
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },

        isRTL() {
            return this.$appState.RTL;
        },
    },
    components: { 
        Noticia, 
        CapitalSocial, 
        PrecosIntegrada, 
        //PrevisaoTempo, 
        ContasPagar, 
        ContasReceber, 
        Mensagens, 
        ModalAceiteTermos, 
    }
}
</script>
