<template>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-dollar"></i>
                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Contas a receber (saldo)</h6>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 100px;">
                    <span class="mb-1 fs-xlarge">{{formatCurrency(totalSaldo)}}</span>
                    <span class="overview-status p-1 teal-bgcolor fs-small">Valor total  {{formatCurrency(totalGeral)}}</span>
                </div>
                <div class="flex align-items-end">
                    <Chart ref="overviewChartData2" type="line" :data="overviewChartData2" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import ContasReceberService from '../../service/ContasReceberService'

    export default {
        name: 'ContasReceber',

		props: {
			matricula: {
				type: Number,
				required: true
			},
		},

        data(){
            return {			
				totalGeral: null,
				totalSaldo: null,
                overviewChartOptions: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    responsive: true,
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            display: false
                        }
                    },
                    tooltips: {
                        enabled: false
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                },
                overviewChartData2: {
                labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
                datasets: [
                    {
                        data: [11, 30, 52, 35, 39, 20, 14, 18, 29],
                        borderColor: [
                            "#4DD0E1",
                        ],
                        backgroundColor: [
                            "rgba(77, 208, 225, 0.8)",
                        ],
                        borderWidth: 2,
                        fill: true,
                        tension: 0.4
                    }
                ]
            }
            }
        },

        created(){
            this.getContasReceberResumido();
        },
		
        methods: {            
            getContasReceberResumido() {
                ContasReceberService.getContasReceberResumo(this.$props.matricula).then((response) => {                    
                    this.totalGeral = response.data.totalGeral;
                    this.totalSaldo = response.data.totalSaldo;
                        
                }); 
            },	
			formatCurrency(value) {
				return value ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : 'R$ 0,00';
			},

            refreshChart() {
                this.setOverviewColors();
            },
        
            setOverviewColors() {
                const { pinkBorderColor, pinkBgColor } = this.getOverviewColors();                
                this.overviewChartData2.datasets[0].borderColor[0] = tealBorderColor;
                this.overviewChartData2.datasets[0].backgroundColor[0] = tealBgColor;
                this.$refs.overviewChartData2.reinit();
            },

            getOverviewColors() {
                const isLight = this.$appState.layoutMode === "light";
                return {
                    tealBorderColor: isLight ? "#009688" : "#26A69A",
                    tealBgColor: isLight ? "#80CBC4" : "#B2DFDB",
                    whiteBorderColor: isLight ? "#ffffff" : "#ffffff",
                    whiteBgColor: isLight ? "rgba(255,255,255,.35)" : "rgba(255,255,255,.35)",
                };
            }
        },
        computed: {
            isRTL() {
                return this.$appState.RTL;
            }
        }
       
    }    
</script>

<style scoped>
    .custom-detail{
        padding: 5px !important;
    }
</style>
