<template>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-dollar"></i>
                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Contas a pagar (saldo)</h6>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 95px;">
                    <span class="mb-1 fs-xlarge">{{formatMoeda(totalSaldo)}}</span>
                    <span class="overview-status p-1 pink-bgcolor fs-small custom-detail">Valor Total {{formatMoeda(totalGeral)}}</span>
                </div>
                <div class="flex align-items-end">
                    <Chart ref="overviewChartData3" type="line" :data="overviewChartData3" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import ContasPagarService from "../../service/ContasPagarService"

    export default {
        name: 'ContasPagar',

		props: {
			matricula: {
				type: Number,
				required: true
			},
		},

        data(){
            return {			
				totalGeral: null,
				totalSaldo: null,
                overviewChartOptions: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    responsive: true,
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            display: false
                        }
                    },
                    tooltips: {
                        enabled: false
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                },
                overviewChartData3: {
                    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
                    datasets: [
                        {
                            data: [20, 29, 39, 36, 45, 24, 28, 20, 15],
                            borderColor: [
                                "#4DD0E1",
                            ],
                            backgroundColor: [
                                "rgba(77, 208, 225, 0.8)",
                            ],
                            borderWidth: 2,
                            fill: true,
                            tension: 0.4
                        }
                    ]
                }
            }
        },

        created(){
            this.getContasAPagarResumido();
        },
		
        methods: {            
            getContasAPagarResumido() {
                ContasPagarService.getContasAPagarResumido(this.$props.matricula, "", "").then((response) => {
						this.totalGeral = response.data.totalGeral;
						this.totalSaldo = response.data.totalSaldo;
				}) ;
			},	

			formatMoeda(value) {
				return value ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : 'R$ 0,00';
			},

            refreshChart() {
                this.setOverviewColors();
            },
        
            setOverviewColors() {
                const { pinkBorderColor, pinkBgColor } = this.getOverviewColors();                
                this.overviewChartData3.datasets[0].borderColor[0] = pinkBorderColor;
                this.overviewChartData3.datasets[0].backgroundColor[0] = pinkBgColor;
                this.$refs.overviewChartData3.reinit();
            },

            getOverviewColors() {
                const isLight = this.$appState.layoutMode === "light";
                return {
                    pinkBorderColor: isLight ? "#E91E63" : "#EC407A",
                    pinkBgColor: isLight ? "#F48FB1" : "#F8BBD0",
                    whiteBorderColor: isLight ? "#ffffff" : "#ffffff",
                    whiteBgColor: isLight ? "rgba(255,255,255,.35)" : "rgba(255,255,255,.35)",
                };
            }
        },
        computed: {
            isRTL() {
                return this.$appState.RTL;
            }
        }
       
    }    
</script>

<style scoped>
    .custom-detail{
        padding: 5px !important;
    }
</style>
