<template>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-comments"></i>
                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Mensagens</h6>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 100px;">
                    <span class="mb-1 fs-xlarge">{{ totalMensagens }}</span>
                    <span class="overview-status p-1 pink-bgcolor fs-small" style="cursor: pointer;" @click="visualizarMensagens">Visualizar Mensagens</span>
                </div>
                <div class="flex align-items-end">
                    <Chart ref="overviewChartData4" type="line" :data="overviewChartData4" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import MensagemService from '../../service/MensagemService';

    export default {
        name: 'Mensagens',

        props: {
            matricula: {
                type: Number,
                required: true
            },
        },

        data(){
            return {			
                totalMensagens: 0,
                overviewChartOptions: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    responsive: true,
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            display: false
                        }
                    },
                    tooltips: {
                        enabled: false
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                },
                
                overviewChartData4: {
                    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
                    datasets: [
                        {
                            data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
                            borderColor: [
                                "#4DD0E1",
                            ],
                            backgroundColor: [
                                "rgba(77, 208, 225, 0.8)",
                            ],
                            borderWidth: 2,
                            fill: true,
                            tension: 0.4
                        }
                    ]
                },
            }
        },

        created(){
            this.getContasReceberResumido();
        },
        
        methods: {            
            getContasReceberResumido() {
                MensagemService.getNotificacoes(this.matricula)
                    .then(({ data }) => {
                        if(data) {
                            this.totalMensagens = data.totalMensagens;
                        }
                        else {
                            this.totalMensagens = 0;
                        }
                    })
                    .catch(error => {
                        this.totalMensagens = 0;
                    });
            },

            visualizarMensagens() {
                this.$router.push(`/mensagens`);
            }
        },

        computed: {
            isRTL() {
                return this.$appState.RTL;
            }
        }
    
    }    
</script>

<style scoped>
    .custom-detail{
        padding: 5px !important;
    }
</style>
