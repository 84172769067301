<template>
    <Dialog v-model:visible="modalExibir" 
            :breakpoints="{'960px': '75vw', '640px': '100vw'}" 
            :style="{width: '50vw'}" 
            header="Alerta" 
            :modal="true" 
            :closeOnEscape="false" 
            :closable="false">
        <div>
            <p>Para continuar acessando o portal, por favor, revise os termos abaixo:</p>
            <div class="p-inputgroup mt-4" v-if="!aceiteTermoUso">
                <span>
                    <Checkbox v-model="termoUso" 
                            :binary="true" 
                            :class="[{'p-invalid': termoUsoInvalida}]" />
                    &nbsp; Concordo com os
                    &nbsp;<a :href="urlTermoUso" 
                            target="_blank" 
                            class="termos">Termos de Uso</a>
                </span>
            </div>

            <div class="p-inputgroup mt-4" v-if="!aceitePoliticaPrivacidade">
                <span>
                    <Checkbox v-model="politicaPrivacidade" 
                            :binary="true" 
                            :class="[{'p-invalid': politicaPrivacidadeInvalida}]" />
                    &nbsp; Concordo com as
                    &nbsp;<a :href="urlPoliticaPrivacidade" 
                            target="_blank" 
                            class="termos">Política de Privacidade</a>
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="Salvar" 
                    icon="pi pi-sae" 
                    class="p-button p-component p-button-success" 
                    @click="salvar()" 
                    :disabled="exibirSalvar()" />
        </template>
    </Dialog>
</template>

<script>
    import TermoService from '../service/TermoService';
    import CooperadoService from '../service/CooperadoService';
    
    export default {
        props: {
			exibirModalTermos: {
				type: Boolean,
				required: true
			},
            aceiteTermoUso: {
				type: String,
				required: false
			},
            aceitePoliticaPrivacidade: {
				type: String,
				required: false
			}
		},
        data() {
            return {
                cooperado: {},
                termoUso: false, 
                termoUsoInvalida: false,
                politicaPrivacidade: false,
                politicaPrivacidadeInvalida: false,

                urlTermoUso: '',
                urlPoliticaPrivacidade: '',

                modalExibir: this.exibirModalTermos,
            };
        },
        mounted() {
            this.buscarTermoUso();
            this.buscarPoliticaPrivacidade();
        },
        methods: {
            buscarTermoUso() {
                TermoService.buscarTermoUso()
                    .then(response => {
                        this.urlTermoUso = response.data;
                    })
                    .catch(error => {
                        this.abrirToast('error', 'Falha', error.response.data, 3000);
                    });
            },

            buscarPoliticaPrivacidade() {
                TermoService.buscarPoliticaPrivacidade()
                    .then(response => {
                        this.urlPoliticaPrivacidade = response.data;
                    })
                    .catch(error => {
                        this.abrirToast('error', 'Falha', error.response.data, 3000);
                    });
            },

            validarCamposObrigatorios() {
                let isValid = true;

                if (!this.aceiteTermoUso && this.termoUso == false) {
                    isValid = false;
                    this.termoUsoInvalida = true;
                }

                if (!this.aceitePoliticaPrivacidade && this.politicaPrivacidade == false) {
                    isValid = false;
                    this.politicaPrivacidadeInvalida = true;
                }

                return isValid;
            },

            salvar() {
                this.loading = true;
                if (!this.validarCamposObrigatorios()) return;

                this.cooperado.matricula = this.getCurrentCooperado().matricula;

                if (this.termoUso) {
                    this.cooperado.dataAceiteTermoUso = new Date().toISOString().substring(0,23);
                } else {
                    this.cooperado.dataAceiteTermoUso = this.getCurrentCooperado().dataAceiteTermoUso.substring(0,23);
                }

                if (this.politicaPrivacidade) {
                    this.cooperado.dataAceitePoliticaPrivacidade = new Date().toISOString().substring(0,23);
                } else {
                    this.cooperado.dataAceitePoliticaPrivacidade = this.getCurrentCooperado().dataAceitePoliticaPrivacidade.substring(0,23);
                }

                CooperadoService.alterarAceiteTermos(this.cooperado)
                    .then(response => {
                        this.abrirToast('success', 'Sucesso', 'Novas políticas atualizadas', 3000);
                        this.modalExibir = false;
                    })
                    .catch(error => {
                        this.abrirToast('error', 'Falha', error.response.data, 3000);
                    });
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },

            getCurrentCooperado() {
                return this.$store.state.auth.user.cooperado;
            },

            exibirSalvar() {
                return (!this.aceiteTermoUso && this.termoUso == false) 
                        || (!this.aceitePoliticaPrivacidade && this.politicaPrivacidade == false);
            },
        },
    }
</script>

<style>
    .termos {
        text-decoration: underline;
    }

    .p-button:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.38) !important;
        opacity: 1;
    }
</style>
